<template>
  <Layout>
    <div class="AccountMT">
      <page-header title="アカウント設定" />
      <div class="AccountMT__content">
        <account-form />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/layouts/main/app'
import PageHeader from '@/layouts/vertical/page-header'
import AccountForm from '@/components/forms/account-mt-form'

export default {
  name: 'AccountMT',
  components: { Layout, PageHeader, AccountForm },
}
</script>
